const DoorIcon = (props) => {

    return (
        <svg className="fill-neutral-900 hover:fill-primary transition duration-300" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
            width="50px" height="50px" viewBox="0 0 552.4 552.4" space="preserve"
        >
            <g>
                <path d="M465.7,26.2c0-9.2-4.9-17.1-11.6-22C452.9,3.6,452.3,3,451,2.4c-3.7-1.8-8-2.4-11.6-2.4H113.1l0,0
		C98.4,0,86.2,11.6,86.2,26.9v67.9h136.5l-14.1-14.1c-3.7-3.7-3.1-9.2,0-12.9l9.2-9.2c1.8-1.8,3.7-2.4,6.1-2.4
		c2.4,0,4.9,0.6,6.7,2.4l45.3,46c1.8,1.8,2.399,3.7,2.399,6.1s-1.199,4.9-2.399,6.7l-45.3,45.9c-3.7,3.7-9.2,3.7-12.9,0l-9.2-9.2
		c-3.7-3.7-3.7-9.2,0-12.9l14.7-14.1H86.8v398.4c0,14.7,11.6,26.9,26.3,26.9h326.2c14.7,0,26.9-11.601,26.9-26.9L465.7,26.2z
		 M352.4,170.7c18.399,0,33.7,15.3,33.7,33.7c0,18.4-15.301,33-33.7,33c-18.4,0-33-14.7-33-33C319.4,186.1,333.4,170.7,352.4,170.7z
		 M235,403.8c-7.3,8.601-12.2,11.601-22,11l-74.1-1.199c-9.2-0.601-16.5-9.801-15.9-17.7c0.6-11,8-17.101,18.4-17.101l67.9-0.6
		l15.9-21.5l0,0L254,380L235,403.8z M412.4,348.101L351.8,334.7c-4.3-1.2-7.3-4.3-9.199-8L328.5,295.5
		c-1.2,1.2-32.399,52.601-32.399,52.601l35.5,26.899c11,9.2,12.199,17.7,8,29.4l-36.7,84.5c-3.101,8-12.2,13.5-21.4,10.399
		c-9.2-3.1-15.3-13.5-11-22.6l31.8-76.5l-47.7-36.1c-12.9-8-17.7-23.301-8.6-38.601l42.8-69.8l-31.2-11l-56.3,28.2
		c-7.3,4.3-16.5,0.6-20.2-6.1c-3.1-6.1-0.6-14.7,5.5-18.4l58.8-30.6c3.1-1.8,6.1-3.1,9.2-3.1s6.1,0.6,8.6,1.2l49.6,15.9
		c1.801,0.6,3.101,1.2,4.301,2.4L344,254.4c3.7,2.4,4.9,6.7,6.101,9.8l13.5,42.2l54.5,14.1c7.3,1.8,11,8.601,11,15.3
		C428.3,342,419.8,350,412.4,348.101z"/>
            </g>
        </svg>
    );
}

export default DoorIcon;